import PropTypes from 'prop-types';
import { LoginApi, LogoutApi, RefreshApi, SwitchUserApi } from 'apis/Auth';
import { useEffect } from 'react';
import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, MENU_OPEN } from 'store/actions';
import { apiErrorSnackBar, apiValidationSnackBar } from 'utils/SnackBar';
import { useNavigate } from 'react-router-dom';
import { CheckPermissions } from 'helpers/AuthHelpers';

// Set Api service Token
const setServiceToken = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', JSON.stringify(serviceToken));
    } else {
        localStorage.removeItem('serviceToken');
    }
};

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const cart = useSelector((state) => state.account);
    // const [localCart, setLocalCart] = useState(cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let localCart = {};

    useEffect(() => {
        // setLocalCart(cart);
        localCart = cart;
    }, [cart]);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = localStorage.getItem('serviceToken');
                if (serviceToken) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            ...cart,
                            isLoggedIn: true
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                dispatch({
                    type: LOGOUT
                });
            }
        };

        return () => {
            init();
        };
    }, []);

    // Login Function
    const login = async (values) => {
        await LoginApi(values)
            .then((res) => {
                if (res.status === 200 && res.data.status === true) {
                    localStorage.setItem('type', 'login');
                    const { data } = res.data;
                    setServiceToken(data.token);

                    const access = data.user.role.permission_group;
                    const accessData = CheckPermissions(access);
                    const ClockOperation = data.time_sheet ? (data.time_sheet.clock ? 'Clock In' : 'Clock Out') : null;
                    const BreakOperation = data.break
                        ? data.break.end_time == null && data.clock.start_time != null
                            ? 'Break In'
                            : 'Break Out'
                        : null;

                    dispatch({ type: MENU_OPEN, id: 'dashboard' });
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedInWithOtherUser: false,
                            isLoggedIn: true,
                            user: data.user,
                            access: accessData,
                            task: data.task == null ? {} : data.task,
                            clock: ClockOperation,
                            breakActive: BreakOperation,
                            time_sheet: data.time_sheet,
                            actualUserData: {},
                            company_logo: data.company_logo
                        }
                    });
                } else {
                    apiValidationSnackBar(res);
                }
            })
            .catch((err) => {
                console.error('AuthContext - login Error >>>', err);
                apiErrorSnackBar(err);
            });
    };

    // Switch User
    const SwitchUser = async (values) => {
        const token = JSON.parse(localStorage.getItem('serviceToken') || '');
        await SwitchUserApi(values)
            .then((res) => {
                if (res.status === 200) {
                    navigate('/');
                    dispatch({ type: MENU_OPEN, id: 'dashboard' });
                    const { data } = res.data;
                    setServiceToken(data.token);

                    const access = data.user.role.permission_group;
                    const accessData = CheckPermissions(access);

                    const ClockOperation = data.time_sheet ? (data.time_sheet.clock ? 'Clock In' : 'Clock Out') : null;

                    const BreakOperation = data.break
                        ? data.break.end_time == null && data.clock.start_time != null
                            ? 'Break In'
                            : 'Break Out'
                        : null;

                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedInWithOtherUser: true,
                            actualUserData: { ...cart, token },
                            isLoggedIn: true,
                            user: data.user,
                            access: accessData,
                            task: data.task == null ? {} : data.task,
                            clock: ClockOperation,
                            breakActive: BreakOperation,
                            time_sheet: data.time_sheet
                        }
                    });
                }
            })
            .catch((err) => {
                apiErrorSnackBar(err);
            });
    };

    const SwitchBack = () => {
        const actual = cart.actualUserData;
        console.log(cart);
        setServiceToken(actual.token);
        navigate('/');
        dispatch({ type: MENU_OPEN, id: 'dashboard' });

        dispatch({
            type: LOGIN,
            payload: {
                ...actual,
                actualUserData: {},
                isLoggedInWithOtherUser: false
            }
        });
    };

    const logOut = async () => {
        await LogoutApi()
            .then(() => {
                dispatch({
                    type: LOGOUT,
                    payload: {
                        isLoggedIn: false
                    }
                });
                setServiceToken(null);
            })
            .catch((err) => {
                apiErrorSnackBar(err);
            });
    };

    const RefreshToken = async () => {
        await RefreshApi()
            .then((res) => {
                if (res.status === 200 && res.data.status === true) {
                    localStorage.setItem('type', 'login');
                    const { data } = res.data;

                    const access = data.user.role.permission_group;
                    const accessData = CheckPermissions(access);
                    const ClockOperation = data.time_sheet ? (data.time_sheet.clock ? 'Clock In' : 'Clock Out') : null;
                    const BreakOperation = data.break
                        ? data.break.end_time == null && data.clock.start_time != null
                            ? 'Break In'
                            : 'Break Out'
                        : null;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            ...cart,
                            isLoggedInWithOtherUser: localCart.isLoggedInWithOtherUser,
                            isLoggedIn: true,
                            user: data.user,
                            access: accessData,
                            task: data.task == null ? {} : data.task,
                            clock: ClockOperation,
                            breakActive: BreakOperation,
                            time_sheet: data.time_sheet,
                            actualUserData: localCart.actualUserData
                        }
                    });
                } else {
                    apiValidationSnackBar(res);
                }
            })
            .catch((err) => {
                console.error('AuthContext - refresh token Error >>>', err);
                apiErrorSnackBar(err);
            });
    };

    const checkRestriction = (slug) => {
        return cart.user.user_type == 'Admin'
            ? true
            : cart.access.restrictions
            ? cart.access.restrictions.filter((a) => a === slug).length > 0
            : false;
    };

    return (
        <AuthContext.Provider value={{ ...cart, checkRestriction, login, logOut, SwitchUser, SwitchBack, RefreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node
};

export default AuthContext;

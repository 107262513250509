import SpeedIcon from '@mui/icons-material/Speed';
import HailIcon from '@mui/icons-material/Hail';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import CampaignIcon from '@mui/icons-material/Campaign';
import { Style } from '@mui/icons-material';
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'main-menu',
    // title: 'Pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: SpeedIcon,
            isIcon: true,
            breadcrumbs: true
        },
        {
            id: 'calender',
            title: 'Calendar',
            type: 'item',
            url: '/calender',
            icon: CalendarMonthOutlinedIcon,
            isIcon: true,
            target: false
        },
        {
            id: 'employees',
            title: 'Employees',
            type: 'item',
            url: '/employees',
            icon: Groups2OutlinedIcon,
            isIcon: true,
            breadcrumbs: true
        },

        {
            id: 'organizations',
            title: 'Organizations',
            type: 'collapse',
            icon: BusinessIcon,
            isIcon: true,
            children: [
                {
                    id: 'organization',
                    title: 'Organization',
                    type: 'item',
                    url: '/organizations/organization',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'location',
                    title: 'Location',
                    type: 'item',
                    url: '/organizations/location',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'department',
                    title: 'Department',
                    type: 'item',
                    url: '/organizations/department',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'designation',
                    title: 'Designation',
                    type: 'item',
                    url: '/organizations/designation',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                }
            ]
        },
        {
            id: 'announcement',
            title: 'Announcement',
            type: 'item',
            url: '/announcement',
            icon: CampaignIcon,
            isIcon: true,
            target: false
        },
        // {
        //     id: 'leaves',
        //     title: 'Leaves',
        //     type: 'item',
        //     url: '/leaves',
        //     icon: HailIcon,
        //     isIcon: true,
        //     breadcrumbs: true
        // },
        {
            id: 'leaves',
            title: 'Leaves',
            type: 'collapse',
            icon: HailIcon,
            isIcon: true,
            children: [
                {
                    id: 'leave',
                    title: 'Leave',
                    type: 'item',
                    url: '/leaves/leave',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'leave-type',
                    title: 'Leave Type',
                    type: 'item',
                    url: '/leaves/leave-type',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'leave-balance',
                    title: 'Leave Balance Report',
                    type: 'item',
                    url: '/leaves/leave-balance',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                }
            ]
        },
        {
            id: 'timesheet',
            title: 'Timesheet',
            type: 'collapse',
            icon: ManageHistoryOutlinedIcon,
            isIcon: true,
            children: [
                {
                    id: 'attendance',
                    title: 'Attendance Sheet',
                    type: 'item',
                    url: '/timesheet/attendance',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'monthly-attendance',
                    title: 'Monthly Timesheet',
                    type: 'item',
                    url: '/timesheet/monthly-attendance',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'time-change-request',
                    title: 'Time Change Request',
                    type: 'item',
                    url: '/timesheet/time-change-request',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'office-shift',
                    title: 'Office Shifts',
                    type: 'item',
                    url: '/timesheet/office-shift',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'add-days-on',
                    title: 'Add Days On',
                    type: 'item',
                    url: '/timesheet/add-days-on',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                }
            ]
        },
        {
            id: 'payroll',
            title: 'Payroll',
            type: 'item',
            url: '/payroll',
            icon: PaymentsOutlinedIcon,
            isIcon: true,
            breadcrumbs: true
        },
        {
            id: 'loan',
            title: 'Loan',
            type: 'item',
            url: '/loan',
            icon: PaymentsOutlinedIcon,
            isIcon: true,
            breadcrumbs: true
        },
        {
            id: 'holidays',
            title: 'Holidays',
            type: 'item',
            url: '/holidays',
            icon: KitesurfingIcon,
            isIcon: true,
            target: false
        },
        {
            id: 'tickets',
            title: 'Tickets',
            type: 'item',
            url: '/tickets',
            icon: Style,
            isIcon: true,
            target: false
        },
        {
            id: 'project-management',
            title: 'Project Management',
            type: 'collapse',
            icon: ContentPasteSearchOutlinedIcon,
            isIcon: true,
            children: [
                {
                    id: 'clients',
                    title: 'Clients',
                    type: 'item',
                    url: '/project-management/clients',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'project',
                    title: 'Project',
                    type: 'item',
                    url: '/project-management/project',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'tasks',
                    title: 'Tasks',
                    type: 'item',
                    url: '/project-management/tasks',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                }
            ]
        },
        {
            id: 'role-permission',
            title: 'Role & Permission',
            type: 'collapse',
            icon: MilitaryTechOutlinedIcon,
            isIcon: true,
            children: [
                {
                    id: 'role',
                    title: 'Role',
                    type: 'item',
                    url: '/role-permission/role',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'permission',
                    title: 'Permission',
                    type: 'item',
                    url: '/role-permission/permission',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                }
            ]
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'collapse',
            url: '/settings',
            icon: SettingsOutlinedIcon,
            isIcon: true,
            children: [
                {
                    id: 'general-settings',
                    title: 'General Settings',
                    type: 'item',
                    url: '/settings/general-settings',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                },
                {
                    id: 'payroll-settings',
                    title: 'Payroll Settings',
                    type: 'item',
                    url: '/settings/payroll-settings',
                    breadcrumbs: true,
                    isIcon: false,
                    target: false
                }
            ]
        }
    ]
};

export default other;

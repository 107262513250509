import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { NotificationsNoneOutlined, NotificationsActiveOutlined } from '@mui/icons-material';
import { apiErrorSnackBar } from 'utils/SnackBar';
import { AnnouncementMonthlyListApi } from 'apis/Announcement';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef(null);

    const [List, setList] = useState({});
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [callApi, setCallApi] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        AnnouncementMonthlyListApi()
            .then((res) => {
                const { data } = res.data;
                setLoader(false);
                setList(data);
            })
            .catch((err) => {
                setLoader(false);
                apiErrorSnackBar(err);
            });
    }, [callApi]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => {
        if (event?.target.value) setValue(event?.target.value);
    };

    return (
        <>
            <Box m={1}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        aria-label="Notification"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary[200],
                            color: theme.palette.secondary[800],
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                borderColor: theme.palette.secondary[800],
                                background: theme.palette.secondary[800],
                                color: theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        {List.total > 0 ? (
                            <NotificationsActiveOutlined stroke={1.5} size="1.3rem" />
                        ) : (
                            <NotificationsNoneOutlined stroke={1.5} size="1.3rem" />
                        )}
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                sx={{ minWidth: 350 }}
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">All Notification</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={List?.total ?? 0}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} p={0}>
                                            <Divider sx={{ my: 0 }} />
                                            <NotificationList Data={List?.data} setCallApi={setCallApi} loader={loader} />
                                        </Grid>
                                    </Grid>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
